import { Injectable } from '@angular/core'
import { TableFilterService } from '@lla-platform/core/core-data-access'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import { tap } from 'rxjs/operators'
import { WorkOrdersStateModel } from './work-orders.model'
import { WorkOrdersService } from '../work-orders.service'
import { IWorkOrdersExtendedResponse } from '../../interfaces/work-orders-summary.interface'
import { GetWorkOrdersList, GetWorkOrdersSummary } from './work-orders.actions'

@State<WorkOrdersStateModel>({
  name: 'workOrders'
})
@Injectable()
export class WorkOrdersState {
  constructor(
    private workOrdersService: WorkOrdersService,
    private tableFilterService: TableFilterService
  ) {}

  @Selector()
  static summaryResponse(state: WorkOrdersStateModel): IWorkOrdersExtendedResponse | undefined {
    if (!state.summaryResponse) {
      return
    }
    return {
      workOrders: state.summaryResponse.workOrders,
      grandTotal: {
        interval30_60: state.summaryResponse.interval30_60 ?? 0,
        interval60_90: state.summaryResponse.interval60_90 ?? 0,
        intervalGreater90: state.summaryResponse.intervalGreater90 ?? 0,
        grandTotals: state.summaryResponse.grandTotals ?? 0
      }
    }
  }

  @Selector()
  static listResponse(state: WorkOrdersStateModel) {
    return state.listResponse
  }

  @Action(GetWorkOrdersSummary)
  getWorkOrdersSummary(ctx: StateContext<WorkOrdersStateModel>, { payload }: GetWorkOrdersSummary) {
    return this.workOrdersService.getWorkOrdersSummary(payload).pipe(
      tap((res) => {
        ctx.patchState({
          summaryResponse: res
        })
      })
    )
  }

  @Action(GetWorkOrdersList)
  getCustomersList(ctx: StateContext<WorkOrdersStateModel>, { info }: GetWorkOrdersList) {
    ctx.patchState({
      listResponse: undefined
    })
    return this.workOrdersService
      .getWorkOrdersList(this.tableFilterService.createRequestPayload(info))
      .pipe(
        tap((res) => {
          ctx.patchState({
            listResponse: res
          })
        })
      )
  }
}
