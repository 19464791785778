import { FilterStateKeys } from './filters.model'

export interface IPageQueryParamsMap {
  [key: string]: FilterStateKeys[]
}

export const AppVersion = '1'
export const NewVersionIndicator = 'new-version'

export const PageQueryParamsMap: IPageQueryParamsMap = {
  '/work-orders/summary': ['workOrdersSummaryFilter', 'workOrdersSummarySortState'],
  '/work-orders/list': ['workOrdersListTableFilters', 'workOrdersListColumnsVisibility'],
  '/receivable/receivables-list': [
    'receivableTableFilters',
    'receivableColumnsVisibility',
    'receivableListReturnUrl'
  ],
  '/receivable/locations': ['shopReceivableSummaryFilter'],
  '/receivable/ar': ['arSummaryFilter'],
  '/receivable/ar-payments': ['arPaymentsSummaryFilter'],
  '/receivable/accounts': ['accountReceivableFilter'],
  '/location-invoices/invoices-list': [
    'invoicesListTableFilters',
    'invoicesListColumnsVisibility',
    'invoicesListReturnUrl'
  ],
  '/location-invoices/gross-invoices-list': [
    'grossListTableFilters',
    'grossListColumnsVisibility',
    'grossListReturnUrl'
  ],
  '/location-invoices/package-gp-list': [
    'gpPackageListTableFilters',
    'gpPackageListColumnsVisibility',
    'gpPackageListReturnUrl'
  ],
  '/location-invoices/invoices-customer-type': ['customersSummaryFilter'],
  '/location-invoices/invoices-deferred-type': ['deferredTypesSummaryFilter', 'deferredTypesSortState'],
  '/location-invoices/invoices-gross-profit': ['grossProfitSummaryFilter', 'grossProfitSortState'],
  '/location-invoices/package-gp-location': [
    'gpPackageByLocationSummaryFilter',
    'gpPackageByLocationSortState'
  ],
  '/location-invoices/package-gp-category': [
    'gpPackageByCategorySummaryFilter',
    'gpPackageByCategorySortState'
  ],
  '/location-invoices/performance-package-gp': ['packageUnperformedSummaryFilter'],
  '/contacts/list': ['customersListTableFilters', 'customersListColumnsVisibility'],
  '/communications/list': ['communicationsListTableFilters', 'communicationsListColumnsVisibility'],
  '/targets/objectives': [
    'weeklyTargetSelectedWeekNumber',
    'weeklyTargetExpandedShopsId',
    'weeklyTargetSelectedRegion',
    'weeklyTargetSelectedDivision'
  ],
  '/targets/locations': [
    'weeklyTargetSelectedWeekNumber',
    'TargetRankingByLocationSelectedRegion',
    'TargetRankingByLocationSelectedDivision',
    'weeklyTargetRankingSortState'
  ],
  '/targets/regions': [
    'weeklyTargetSelectedWeekNumber',
    'TargetRankingByRegionSelectedRegion',
    'TargetRankingByRegionSelectedDivision',
    'weeklyTargetRankingSortState'
  ],
  '/reports/calls': ['callsReportFilter'],
  '/payments/payments-list': ['paymentsListTableFilters', 'paymentsListColumnsVisibility'],
  '/inventories/list': ['inventoriesListTableFilters', 'inventoriesListColumnsVisibility'],
  '/admin/location-tires': ['adminShopTiresExpandedShopsId'],
  '/promotions/list': ['promotionsExpandedShopsId'],
  '/tires/summary': ['tiresSummaryFilter'],
  '/tires/list': ['tiresListTableFilters', 'tiresListColumnsVisibility', 'tiresListReturnUrl'],
  '/admin/user-list': ['adminUserListSortState'],
  '/admin/promotions-list': ['adminPromotionsListSortState'],
  '/tracker/summary': ['trackerLocationId'],
  '/tires/lost-tire': ['lostTireLocationId'],
  '/contacts/communication-type': ['communicationTypeFilter'],
  '/location-invoices/weekly-car-count': ['weeklyCarCountsFilter'],
  '/tires/lost-tire-list': ['lostTiresListTableFilters', 'lostTiresListColumnsVisibility'],
  '/admin/email-promotions': ['promotionsUsersExpandedIds']
}

export const PersistFilters: FilterStateKeys[] = [
  'showLargeMenu',
  'showAppliedTableFilters',
  'weeklyTargetExpandedShopsId',
  'adminShopTiresExpandedShopsId',
  'promotionsExpandedShopsId',
  'promotionsUsersExpandedIds'
]
